module.exports = [{
      plugin: require('/zeit/2738c164/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":640},
    },{
      plugin: require('/zeit/2738c164/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"<<YOUR_OWN_KEY>>"},
    },{
      plugin: require('/zeit/2738c164/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/2738c164/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
